<template>
  <aside class="airdrop-aside">
    <div v-if="Object.keys(previewItem).length">
      <div class="card-header">
        <div class="card-image">
          <v-avatar size="45">
            <v-img
              :src="getContentfulImage(previewItem.logo, 'airdrop')"
              :style="{ backgroundColor: previewItem.logo ? '' : 'var(--tertiary-bg-color)'} "
              alt="airdrop avatar"
            />
          </v-avatar>
        </div>
        <div class="card-title-block">
          <span class="card-title">{{ previewItem.title }}</span>
          <span class="card-label">{{ previewItem.ticker }}</span>
        </div>
        <AirdropLike
          :id="previewItem.id"
          :liked="previewItem.isLiked"
          :likes="previewItem.likesAmount"
          @toggleLike="$emit('toggleLike', $event)"
        />
      </div>
      <div class="card-status">
        <div class="status-block">
          <span class="status">Airdrop</span>
          <BRPeriodChip
            class="status-chip"
            :date-start="previewItem.dateStart"
            :date-end="previewItem.dateEnd"
          />
        </div>
        <div class="status-date">
          <p class="status-date-title">
            Ending date:
          </p>
          <span>
            {{ formatDate(previewItem.dateEnd) }}
            {{ getTimeLeft ? `Ends in ${getTimeLeft}` : 'No time left' }}
          </span>
        </div>
      </div>
      <div class="card-info">
        <div v-if="previewItem.estValue" class="info-item">
          <span class="card-info-title">Est. Value</span>
          <span class="card-info-value">{{ previewItem.estValue }}</span>
        </div>
        <div v-if="previewItem.tokensToClaim" class="info-item">
          <span class="card-info-title">Tokens to claim</span>
          <span class="card-info-value">{{ previewItem.tokensToClaim }}</span>
        </div>
        <div class="info-item">
          <span class="card-info-title">Max participants</span>
          <span class="card-info-value">{{ previewItem.maxParticipants || 'Unlimited' }}</span>
        </div>
        <div v-if="previewItem.chains" class="info-item">
          <span class="card-info-title">Chains</span>
          <span class="card-info-value">{{ previewItem.chains }}</span>
        </div>
        <div v-if="previewItem.holdingRequirement" class="info-item">
          <span class="card-info-title">Holding requirement</span>
          <span class="card-info-value">{{ previewItem.holdingRequirement }}</span>
        </div>
        <div v-if="previewItem.socialRequirement" class="info-item">
          <span class="card-info-title">Social requirement</span>
          <span class="card-info-value">
            <BRIcon
              v-for="(item, index) in previewItem.socialRequirement"
              :key="index"
              class="social-icon"
              :icon="socialIcons[item]"
              fill-color="var(--secondary-text-color)"
              width="20"
              height="20"
            />
          </span>
        </div>
        <div class="info-item">
          <span class="card-info-title">End date</span>
          <span class="card-info-value">{{ formatDate(previewItem.dateEnd) }}</span>
        </div>
      </div>
      <div v-if="previewItem.aboutToken" class="card-about">
        <span class="about-title">About AirDrop</span>
        <p class="about-text">
          <BRRichTextRenderer :document="previewItem.aboutToken" />
        </p>
        <router-link
          :to="{ name: 'AirdropItemPage', params: { id: previewItem.id } }"
          class="about-link"
        >
          Read more
        </router-link>
      </div>
      <BRButton
        v-if="previewItem.joinAirdropLink"
        :href="previewItem.joinAirdropLink"
        class="join-button"
        dark
        target="_blank"
      >
        JOIN AIRDROP
      </BRButton>
      <router-link
        v-if="$route.name !== 'AirdropItemPage'"
        :to="{ name: 'AirdropItemPage', params: { id: previewItem.id } }"
        class="card-link"
      >
        Read full review
      </router-link>
    </div>
    <div v-else class="card-empty">
      There is no Airdrops
    </div>
  </aside>
</template>

<script>
import { formatDate, timeLeft } from '@/services/date'
import BRPeriodChip from '@/components/BRComponents/BRPeriodChip.vue'
import BRButton from '@/components/BRComponents/BRButton.vue'
import BRIcon from '@/components/BRComponents/BRIcon.vue'
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'
import AirdropLike from '@/components/AirdropLike.vue'
import images from '@/mixins/image'

export default {
  name: 'AirdropAside',
  components: {
    AirdropLike,
    BRPeriodChip,
    BRButton,
    BRIcon,
    BRRichTextRenderer
  },
  mixins: [images],
  props: {
    previewItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      socialIcons: {
        Discord: 'discord',
        Instagram: 'socials-instagram',
        Facebook: 'socials-facebook',
        Medium: 'medium',
        Reddit: 'reddit',
        Telegram: 'telegram',
        Twitter: 'socials-twitter',
        YouTube: 'youtube'
      }
    }
  },
  computed: {
    getTimeLeft() {
      return this.timeLeft(new Date(), this.previewItem.dateEnd)
    }
  },
  methods: {
    timeLeft,
    formatDate
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

[data-theme="dark"] .airdrop-aside {
  --label-bg-color: #1d2229;
}

.airdrop-aside {
  @include content;

  padding: 22px 20px 20px;
  height: fit-content;
  max-width: 300px;

  @include breakpoint-reverse(medium) {
    min-width: 100%;
  }
}

.card-header {
  display: flex;
  align-items: center;
  border-bottom: var(--default-border);
  padding: 0 0 17px;
}

.card-image {
  margin-right: 13px;
}

.card-title-block {
  margin-right: auto;
  max-width: 160px;

  .card-title {
    display: block;
    margin-bottom: 2px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .card-label {
    display: block;
    border-radius: var(--border-radius-default);
    padding: 4px 14px;
    width: fit-content;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-xs);
    color: var(--secondary-text-color);
    background-color: var(--label-bg-color);
  }
}

.card-status {
  border-bottom: var(--default-border);
  padding: 19px 0 10px;

  .status-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    font-size: var(--font-h3);

    .status {
      margin-right: 8px;
      font-weight: var(--font-weight-bold);
    }
  }

  .status-chip {
    margin-right: 3px;
  }

  .status-date-title {
    margin-bottom: 9px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-sm);
    color: var(--text-color);
  }

  .status-date {
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }
}

.card-info {
  border-bottom: var(--default-border);
  padding: 16px 0 19px;

  .info-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-info-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    white-space: nowrap;
  }

  .card-info-value {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--font-base);
    word-break: break-word;
    text-align: right;
    color: var(--secondary-text-color);
    gap: 4px;
  }

  .social-icon {
    width: 20px;
    height: 20px;
  }
}

.card-about {
  padding: 15px 0 20px;

  .about-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
  }

  .about-text {
    /* stylelint-disable */
    display: -webkit-box;
    /* stylelint-enable */
    overflow: hidden;
    margin: 9px 0 0;
    font-size: var(--font-base);
    color: var(--secondary-text-color);
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }

  .about-link {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--link-color);
  }
}

.card-link {
  display: block;
  margin-top: 18px;
  width: 100%;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-base);
  text-align: center;
  color: var(--primary-text-color);
}

.join-button {
  width: 100%;
}
</style>
