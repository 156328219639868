<template>
  <button class="like-button" @click.stop="$emit('toggleLike')">
    <BRIcon
      :class="{ 'like-marked': liked }"
      :fill-color="liked ? 'var(--primary-icon-color)' : 'none'"
      :stroke-color="liked ? 'var(--primary-icon-color)' : 'var(--icon-color)'"
      class="like-icon"
      icon="like"
    />
    <span v-if="likes" class="likes-counter">{{ likes }}</span>
  </button>
</template>

<script>
import BRIcon from '@/components/BRComponents/BRIcon.vue'

export default {
  name: 'BRLikeButton',
  components: {
    BRIcon
  },
  props: {
    liked: {
      type: Boolean,
      default: false
    },
    likes: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      required: true
    }
  },
  emits: ['toggleLike']
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.like-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .like-icon {
    width: 17px;
    height: 17px;
    stroke: var(--text-color);
    cursor: pointer;

    @include breakpoint-reverse(small) {
      width: 15px;
      height: 15px;
    }
  }

  .like-marked::v-deep .blockreviews-icon {
    fill: var(--primary-icon-color);
    stroke: var(--primary-icon-color);
  }

  .likes-counter {
    font-size: var(--font-sm);
    color: var(--secondary-text-color);
  }
}
</style>
