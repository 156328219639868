<template>
  <BRLikeButton
    id="id"
    :liked="isLiked"
    :likes="likesAmount"
    @toggleLike="toggleLike"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
import BRLikeButton from '@/components/BRComponents/BRLikeButton.vue'

export default {
  name: 'AirdropLike',
  components: {
    BRLikeButton
  },
  props: {
    liked: {
      type: Boolean,
      default: false
    },
    likes: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLiked: this.liked,
      likesAmount: this.likes
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  watch: {
    liked(value) {
      this.isLiked = value
    },
    likes(value) {
      this.likesAmount = value
    }
  },
  methods: {
    async toggleLike() {
      if (!this.getUserDB) {
        await this.$router.push({
          name: 'Login',
          query: { redirect: this.$route.path }
        })
        return
      }

      const {
        data: isLiked,
        error
      } = await api.likes.toggleLike(this.id, 'airdrop')

      if (error) return

      this.isLiked = isLiked
      this.likesAmount = isLiked ? this.likesAmount + 1 : this.likesAmount - 1
      this.$emit('toggleLike', { isLiked, likesAmount: this.likesAmount })
    }
  }
}
</script>
