<template>
  <div class="period-chip" :style="`background-color: ${period.color}`">
    {{ period.text }}
  </div>
</template>

<script>
export default {
  name: 'BRPeriodChip',
  props: {
    dateStart: {
      type: String,
      required: true
    },
    dateEnd: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateStartFormatted: new Date(this.dateStart),
      dateEndFormatted: new Date(this.dateEnd),
      dateCurrent: new Date(),
      periods: {
        upcoming: {
          text: 'Starting soon',
          color: 'var(--chip-upcoming)'
        },
        ongoing: {
          text: 'Active',
          color: 'var(--chip-ongoing)'
        },
        endingSoon: {
          text: 'Ending soon',
          color: 'var(--chip-ending-soon)'
        },
        ended: {
          text: 'Ended',
          color: 'var(--chip-ended)'
        },
        tba: {
          text: 'Dates to be announced',
          color: 'var(--chip-upcoming)'
        }
      }
    }
  },
  computed: {
    isUpcoming() {
      return this.dateStartFormatted > this.dateCurrent
    },
    isOngoing() {
      return this.dateStartFormatted <= this.dateCurrent && this.dateEndFormatted >= this.dateCurrent
    },
    isEndingSoon() {
      const TWO_WEEKS_IN_MS = 1209600000
      return this.dateEndFormatted - this.dateCurrent < TWO_WEEKS_IN_MS
    },
    isEnded() {
      return this.dateEndFormatted < this.dateCurrent
    },
    period() {
      switch (true) {
        case this.isUpcoming:
          return this.periods.upcoming
        case this.isOngoing && !this.isEndingSoon:
          return this.periods.ongoing
        case this.isOngoing && this.isEndingSoon:
          return this.periods.endingSoon
        case this.isEnded:
          return this.periods.ended
        default:
          return this.periods.tba
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.period-chip {
  display: inline-block;
  border-radius: 5px;
  padding: 6px 17px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-xs);
  white-space: nowrap;
  text-align: center;
  color: var(--button-text-color);
}
</style>
